import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Shayna Panthee', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'This is my Portfolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello, my name is',
  name: 'Shayna Panthee',
  subtitle: 'Welcome to my Portfolio!',
  cta: 'Know more',
};

// ABOUT DATA
export const aboutData = {
  img: 'shayna.jpeg',
  paragraphOne:
    "As an architectural student, my passion for design and architecture drives me to seek out new knowledge and skills that can be used to create innovative and impactful designs. I am particularly interested in contemporary and modern/urban architecture, and I'm eager to work on projects that challenge traditional ways of thinking. I take pride in producing high-quality work and am committed to exceeding the expectations of my clients.",
  paragraphTwo:
    'As an individual with strong values, I prioritize honesty, integrity, and respect in all aspects of my work. I am dedicated to fostering collaborative and inclusive environments that encourage open communication and diverse perspectives.',
  paragraphThree:
    'I am committed to producing work that reflects my values of sustainability, accessibility, and social responsibility, and that has a positive impact on the communities and individuals it serves.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'Tower.jpg',
    title: 'Dharahara Tower - Reconstruction',
    info: 'The Dharahara reconstruction project in Nepal aims to restore the national tower that was destroyed in an earthquake, while also creating an attractive public space for visitors. The replica tower is just one of the new features, the park also include a museum, a public eatery, and public washrooms. The entrance to the park is designed to impress, and the park itself provides a relaxing environment with seating, greenery, and water features. Convenient transportation options, including a large parking lot, taxi, and bus stands, make it easy for visitors to access the park. Overall, the project rebuilds the landmark and creates a beautiful and engaging public space that is sure to attract visitors from around the world.',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Rev.jpg',
    title: 'REV Re-Clad',
    info: "Ron Eydt Village (REV), a student residence at the University of Waterloo, has undergone a reclad project to provide a comfortable and attractive living environment while ensuring durability. The original facade had low thermal performance and was aesthetically unappealing, which led to the replacement of the entire enclosure system to enhance thermal performance, comfort, and experience. The reclad project resulted in a modern facade that maintains a sense of community while also improving energy efficiency. A new canopy construction was added to offer shelter for students and enhance the building's aesthetic image. Overall, the reclad project has created a more sustainable and durable living space for future generations of students.",
    info2: '',
    url: 'https://drive.google.com/file/d/1h1fL2sJMKjvqpSbDU5eyWrR9REXi_Eoe/view?usp=sharing',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Pavillion.jpg',
    title: 'Bayan Pavillion',
    info: "The Kitchener Banyan project creates an inviting and dynamic space in Waterloo Park, promoting outdoor recreation and relaxation for the public. The flexible and functional pavilion provides sheltered seating to protect visitors from the elements and ensure a comfortable experience. Located at the park's edge, the pavilion serves as a transitional piece from the natural landscape to the man-made structures in the city, creating a harmonious connection between the two. The project's thoughtful design enhances the overall park experience, providing a space for visitors to rest, relax, and engage in recreational activities.",
    info2: '',
    url: 'https://drive.google.com/file/d/1HCDz-T1Bl5fFcbM7isYcMQ8lBlb6u7t0/view?usp=sharing',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Niwas.JPG',
    title: 'Parajuli Niwas',
    info: 'Parajuli Niwas is an exceptional three-and-a-half-story residential property that comfortably accommodates 10-15 people. Its unique design features ten bedrooms, a lounge area on each floor, a serene prayer room on the top level, and convenient parking for two vehicles. Constructed under the guidelines of Vastu Shatra, the traditional Indian system of architecture, the property is optimally designed to promote a harmonious balance of energy, enhancing the well-being and happiness of residents. With its tranquil ambiance, modern amenities, and thoughtful design, Parajuli Niwas is a perfect place to call home, whether for a temporary or long-term stay.',
    info2: '',
    url: 'https://drive.google.com/file/d/15t2zdGDYBRIp0ziWfWZyUuXyEmPzQFdm/view?usp=sharing',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'Covid.JPG',
    title: 'Covid Cabin',
    info: "The Covid Cabin is a custom-built dorm room like cabin designed specifically for students who are unable to experience traditional student life during  pandemic . It maximizes efficiency and minimizes distraction to provide an optimal study environment. The top priority of the Covid Cabin is to promote interaction with nature, as it's essential for physical and mental well-being. The cabin features an outdoor patio and a large roof window for natural light and scenic views. The workspace area is thoughtfully separated to provide an optimal study environment. The Covid Cabin provides an innovative, functional, and peaceful space for students to live and study, ensuring they have a comfortable and productive environment during Covid-19 Pandemic.",
    info2: '',
    url: 'https://drive.google.com/file/d/1si-3U65n6OKsMIQaEGocbiR2wsmtxnnz/view?usp=sharing',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'website.jpeg',
    title: 'Art and HandSketches',
    info: 'These are collections of some drawings, Arts and Handsketches I did during my time of lesure.',
    info2: '',
    url: 'https://drive.google.com/file/d/1rNNGItxM5iwxxux3YO8RZchtG4etqyMv/view?usp=sharing',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'shayna.panthee@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/shayna-panthee/',
    },
    // {
    //   id: nanoid(),
    //   name: 'github',
    //   url: '',
    // },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
